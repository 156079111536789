@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caladea:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Carme&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*[data-theme="light"] {
  --color-primary: #6482ad;
  --color-primary1: #42618c;
  --color-primary2: #809dc7;
  --color-secondary: #e2dad6;
  --color-secondary1: #e2dad6;
  --color-secondary2: #f5eded;
  --color-neutral: #243d4b;
  --color-neutral1: #989898;
  --color-neutral2: #fafafa;
}

*[data-theme="dark"] {
  --color-primary: #72b261;
  --color-primary1: #61a24e;
  --color-primary2: #81b248;
  --color-secondary: #183444;
  --color-secondary1: #395260;
  --color-secondary2: #e1e1e1;
  --color-neutral: #fafafa;
  --color-neutral1: #989898;
  --color-neutral2: #243d4b;
}

.swiper {
    /* position: relative; */
    width: 100% !important;
    height: 100% !important;
    padding: 30px 40px !important;
  }

@media only screen and (max-width: 600px) {
  .swiper {
    width: 100% !important;
    height: 100% !important;
    padding: 00px 0px !important;
  }
}
